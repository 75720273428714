<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="选择商品" prop="goods_id">
        <el-button type="text" @click="showModal=true" v-if="!info.id">选择商品</el-button>
        <div class="goods-box" v-if="goodsInfo">
          <div class="item">
            <img :src="goodsInfo.img" class="img">
            <div class="name">{{ goodsInfo.title }}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="预售时间" prop="send_amount">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="付款时间" prop="send_amount">
        <el-date-picker
            size="small"
            v-model="dateRange2"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="定金类型" prop="presale_type">
        <el-radio-group v-model="info.presale_type">
          <el-radio :label="0">直接付款</el-radio>
          <el-radio :label="1">定金付款</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="定金" prop="presale_price" v-if="info.presale_type==1">
        <el-input v-model="info.presale_price" placeholder="请输入金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="发货" prop="delivery_time">
        <div style="display: flex; align-items: center">
          <el-input v-model="info.delivery_time" placeholder="请输入发货天数">
            <template slot="prepend">活动结束后</template>
            <template slot="append">天发货</template>
          </el-input>
          <el-tooltip class="item" effect="dark" content="几天后发货，0为活动结束发货" placement="top-start">
            <i class="el-icon-warning-outline" style="font-size: 16px; margin-left: 5px;"></i>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择商品"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <goods-list :type="1" :goodsType="1" :selectFunc="setGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import goodsList from "@/components/Goods/GoodsList";
export default {
  data() {
    return {
      showModal: false,
      dateRange: ['',''],
      dateRange2: ['',''],
      goodsInfo: false,
      info: {
        id: 0,
        goods_id: '',
        presale_price: '',
        presale_type: '',
        delivery_time: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        presale_price: [
          { required: true, message: '请输入定金', trigger: 'blur' },
          { validator: this.$validate.isFloat, trigger: 'blur' }
        ],
        goods_id: [
          { required: true, message: '请选择商品', trigger: 'blur' }
        ]
      }
    };
  },
  components: {
    goodsList
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setGoods(goods) {
      this.goodsInfo = goods
      this.info.goods_id = goods.id
      this.showModal = false
    },
    getInfo() {
      this.showLoading()
      this.$api.marking.presaleInfo({id: this.info.id}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.info = res.data
          this.goodsInfo = this.info.goods
          this.dateRange = [this.info.presale_start_time,this.info.presale_end_time]
          this.dateRange2 = [this.info.pay_start_time,this.info.pay_end_time]
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = {
            id: this.info.id,
            goods_id: this.goodsInfo.id,
            presale_start_time: this.dateRange[0],
            presale_end_time: this.dateRange[1],
            pay_start_time: this.dateRange2[0],
            pay_end_time: this.dateRange2[1],
            presale_type: this.info.presale_type,
            presale_price: this.info.presale_price,
            delivery_time: this.info.delivery_time,
          }
          if(param.presale_start_time=='') {
            this.fail('预售时间不能为空')
            return false;
          }
          if(param.pay_start_time=='') {
            this.fail('付款时间不能为空')
            return false;
          }
          this.$api.marking.presaleEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 500px;
  }
</style>
